import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearError, scanCode } from "../../redux/actions/userActions";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import uploadReceiptBtnEn from "../../assets/newUploadReceiptButton.png";
import uploadReceiptBtnBm from "../../assets/newBmUploadReceiptButton.png";
import giftBox from "../../assets/giftBox.png";
import xLogo from "../../assets/svgs/xLogo.svg";
import scan3xHeader from "../../assets/scan3xHeader.png";
import scan3xHeaderBM from "../../assets/scan3xHeaderBM.png";
import scan5xHeader from "../../assets/scan5xHeader.png";
import scan5xHeaderBM from "../../assets/scan5xHeaderBM.png";
import scan10xHeader from "../../assets/scan10xHeader.png";
import scan10xHeaderBM from "../../assets/scan10xHeaderBM.png";
import { useLocation } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import { Link } from "react-router-dom/cjs/react-router-dom";

const VerifyCodeSuccess = ({
  code,
  setCode,
  history,
  user,
  scanCode,
  page,
  clearError,
}) => {
  const location = useLocation();
  const staty = location.state;
  const { t } = useTranslation();
  const [showModal, setShow] = useState(false);
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const dispatch = useDispatch();
  const language = useSelector((state) => state?.pageReducer?.language);
  const [numScan, setNumScan] = useState(0);

  const scanReward = {
    scanHeader: {
      1: scan3xHeader,
      5: scan5xHeader,
      10: scan10xHeader,
    },

    scanHeaderBM: {
      1: scan3xHeaderBM,
      5: scan5xHeaderBM,
      10: scan10xHeaderBM,
    },

    scanTitle: {
      1: (
        <p className="addToCartTitle scan3xTitle">
          {t("scan3xTitle1") + staty?.gainedPoints + " " + t("scan3xTitle2")}
        </p>
      ),
      5: "",
      10: "",
    },

    scanDesc2: {
      1: "scan3xDescription2",
      5: "scan5xDescription2",
      10: "scan10xDescription2",
    },

    scanDesc4: {
      1: "scan3xDescription4",
      5: "scan5xDescription4",
      10: "scan10xDescription4",
    },
  };

  // useEffect(() => {
  // 	window.Insider.eventManager.dispatch("init-manager:re-initialize");
  // }, [code, page.isLoading]);

  useEffect(() => {
    console.log("checking staty: ", staty);
    if (
      staty?.foundScanned === 0 ||
      staty?.foundScanned === 4 ||
      staty?.foundScanned === 9
    ) {
      setNumScan(staty?.foundScanned + 1);
      setShowMilestoneModal(true);
    } else {
      setShow(true);
    }
  }, [staty]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (code && code.length === 10) {
      scanCode(
        { code: code.toUpperCase(), id: user.id },
        (gainedPoints, code_group, brand, format, size) => {
          window.Insider.track("events", [
            {
              event_name: "uc_registration",
              // timestamp: new Date().toISOString(),
              event_params: {
                point_value: gainedPoints,
                code_group,
                unique_code: code.toUpperCase(),
                brand,
                format,
                c_size: size,
              },
            },
          ]);
          setShow(gainedPoints);
        }
      );
    } else {
      alert(t("atleastten"));
    }
  };

  return (
    <div className="text-center" style={{ paddingBottom: "100px" }}>
      <p className="addToCartTitle">{t("registercode")}</p>
      <p className="pickSampleModalText mt-5 pb-3">
        {t("youcanalso")}
        {/* <br /> {t("theuc")} */}
      </p>
      <div>
        <form onSubmit={handleSubmit} id="ucForm">
          <div id="ucInputContainer">
            <input
              id="ucInput"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value.toUpperCase())}
              placeholder={t("uq")}
              maxLength={10}
              required
            />
          </div>
          <div className="text-center my-5">
            <p className="uc-tnc">{t("ucTNCterm")}</p>
            <p className="uc-tnc">
              {t("receiptUploadTnc")}
              <br />
              {t("receiptUploadTnc2")}
            </p>
            <Loader
              component={
                <input
                  type="submit"
                  value={t("submit")}
                  id="ucSubmit"
                  className="pickAddressConfirmButton"
                />
              }
            />
          </div>
        </form>
        {/* <h5 style={{ margin: "0", fontSize: "12px", paddingBottom: "0" }} className="pickSampleModalText mt-2">{t("ucTNCterm")}</h5>
				<h6 style={{ margin: "0", fontSize: "12px", paddingBottom: "0" }} className="pickSampleModalText mt-2">{t("ucTNC")}</h6>
				<h6 style={{ margin: "0", fontSize: "12px", paddingTop: "0" }} className="pickSampleModalText">{t("ucTNC2")}</h6> */}
        <div className="px-4">
          <Slider
            autoplay={true}
            autoplaySpeed={4000}
            easing="ease-in"
            dots={true}
            className="slider-upload-receipt-banner"
          >
            <div className="upreceipt-slider-div">
              <img
                src={
                  language === "en" ? uploadReceiptBtnEn : uploadReceiptBtnBm
                }
                alt="for-all-drypers-baby-care-products-only"
                className="mt-3"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </Slider>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => {
          clearError();
          setShow(false);
        }}
        centered
        backdrop="static"
      >
        <Modal.Body className="text-center">
          {page.error ? (
            <div>
              <p className="addToCartTitle">{t("error")}</p>
              <p className="mt-3 pickSampleModalText">{page.error}</p>

              <div>
                <button
                  className="pickAddressConfirmButton"
                  onClick={() => {
                    clearError();
                    setShow(false);
                    history.push("/scancode");
                  }}
                >
                  OKAY
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <img
                  src={giftBox}
                  alt="gift-box"
                  className="img-fluid mod-image"
                />
              </div>
              <p
                className="addToCartTitle"
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginTop: "6px",
                  color: "#223C96",
                  fontFamily: "Knewave",
                }}
              >
                {t("newRegistered")}
              </p>
              <p className="mt-3 pickSampleModalText">
                {t("youHaveReceived") + staty?.gainedPoints + t("smallPoints")}
                <br />
              </p>

              <div>
                <button
                  id="after-redeem-ok"
                  className="pickAddressConfirmButton"
                  onClick={() => {
                    clearError();
                    setShow(false);
                    history.push("/scancode");
                  }}
                >
                  OKAY
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showMilestoneModal}
        onHide={() => {
          clearError();
        }}
        dialogClassName="modal-90w modal3x"
        centered
      >
        <Modal.Body className="text-center">
          <div
            className="close-button"
            onClick={() => {
              setShowMilestoneModal(false);
              history.push("/scancode");
            }}
          >
            <img src={xLogo} alt="close-button"></img>
          </div>
          <div>
            <div>
              <img
                src={
                  language === "bm"
                    ? scanReward?.scanHeaderBM[numScan]
                    : scanReward?.scanHeader[numScan]
                }
                alt="scan3xHeader"
                className="img-fluid w-90"
              />
            </div>
            {scanReward?.scanTitle[numScan]}
            <p className="mt-3 scan3xDescription">
              {t("scan3xDescription1")}{" "}
              <strong>{t(scanReward?.scanDesc2[numScan])}</strong>
              {t("scan3xDescription3")}
              <strong>{t(scanReward?.scanDesc4[numScan])}</strong>
              {t("scan3xDescription5")}
              <br />
            </p>
            <p className="mt-3 scan3xDescription">
              {t("scan3xDescription6")}
              <br />
              <Link
                to={{ pathname: "/pointshop", state: { origin: "voucher" } }}
                id="after-redeem-point-shop mt-2"
              >
                <strong>{t("scan3XPointShop")}</strong>
              </Link>
            </p>

            <div>
              <button
                id="after-redeem-ok"
                className="pickAddressConfirmButton"
                onClick={() => {
                  clearError();
                  setShow(false);
                  history.push("/dashboard");
                }}
              >
                {t("scan3xButton")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={page.error} onHide={() => clearError()} centered>
        <Modal.Body className="text-center">
          <div>
            <p className="addToCartTitle">{t("error")}</p>
            <p className="mt-3 pickSampleModalText">{page.error}</p>
          </div>

          <div>
            <button
              className="pickAddressConfirmButton"
              onClick={() => clearError()}
            >
              OKAY
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    page: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    scanCode: (data, callback) => dispatch(scanCode(data, callback)),
    clearError: (data) => dispatch(clearError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCodeSuccess);
